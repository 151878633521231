import { FilterSet } from '@/core/interface'
import {
  GetImageSetSummaries,
  GetObjectFilterCatagories,
  GetSelectedAnnotationObjects,
  ImageSetSummary,
} from '@/graphql/generated'
import { orderBy, uniqBy } from 'lodash'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { UnwrapRef } from 'vue'

export type ImageSets = NonNullable<GetImageSetSummaries['getImageSetSummaries']['image_sets']>
type AnnotationObject = NonNullable<GetSelectedAnnotationObjects['getSelectedAnnotationObjects']>

export const useGalleryStore = defineStore('gallery', {
  state: () => {
    const search = ref('')
    const isStarlessView = ref(false)
    const queueDeleteImages = ref<{ sid: string; creationTimestamp: number }[]>([])
    const isFinishMore = ref(false)
    const view = ref<'classic' | 'fluid'>('fluid')
    const switchView = () => {
      view.value = view.value === 'classic' ? 'fluid' : 'classic'
    }
    const setView = (newView: 'classic' | 'fluid') => {
      view.value = newView
    }

    const setStarlessView = (newView: boolean) => {
      isStarlessView.value = newView
    }

    const queueDeleteImageIds = computed(() => queueDeleteImages.value.map((image) => image.sid))

    const views = ref<string[]>(['classic', 'fluid'])

    const filterCategories = ref<GetObjectFilterCatagories['getObjectFilterCatagories']>([])

    const filter = ref<FilterSet>({})

    const setFilter = (key: keyof FilterSet, value: boolean) => {
      filter.value = { ...filter.value, [key]: value }
    }

    const resetFilter = () => {
      Object.keys(filter.value).forEach((key) => {
        filter.value[key] = false
      })
    }

    const filterPanel = ref<boolean>(false)

    const switchFilterPanel = () => {
      filterPanel.value = !filterPanel.value
    }

    const filterCount = ref<number>(0)

    watch(
      filter,
      (newFilter) => {
        const count = Object.values(newFilter).filter((val) => val === true).length
        filterCount.value = count
      },
      { deep: true },
    )

    const deleteSetTimer = ref<Date | undefined>()

    const setDeleteSetTimer = (minutes: number = 60) => {
      const exp: Date = new Date()
      exp.setTime(exp.getTime() + minutes * 60000)
      deleteSetTimer.value = exp
    }

    const imageSets = ref<ImageSets>([])
    const setImageSets = (val: ImageSets): void => {
      console.trace(
        'setImageSets',
        val,
        orderBy(
          uniqBy(
            val.filter((set) => set?.set_id),
            'set_id',
          ),
          ['creation_timestamp'],
          ['desc'],
        ),
      )

      imageSets.value = orderBy(
        uniqBy(
          val.filter((set) => set?.set_id),
          'set_id',
        ),
        ['creation_timestamp'],
        ['desc'],
      )
    }

    const filterIds = computed((): string[] => {
      const filterSelection =
        filterCategories.value?.filter((item) => {
          const keys = Object.keys(filter.value)
          return keys.some((key) => filter.value[key] && key === item?.id)
        }) ?? []

      return filterSelection?.map((item) => item?.id ?? '')
    })

    const addImageSet = (imageSet: ImageSetSummary) => {
      setImageSets([imageSet, ...imageSets.value])
    }

    const removeImageSet = (imageSetId: string) => {
      setImageSets(imageSets.value.filter((set) => set?.set_id !== imageSetId))
    }

    const annotationObject = ref<AnnotationObject>([])
    const setAnnotationObject = (val: AnnotationObject): void => {
      annotationObject.value = val
    }

    const searchSelectedTag = ref<{ label: string; value: string; isTag: boolean }>()
    const setSearchSelectedTag = (val: UnwrapRef<typeof searchSelectedTag>): void => {
      searchSelectedTag.value = val
    }

    const isFavorite = ref(false)
    const setIsFavorite = (val: boolean): void => {
      isFavorite.value = val
    }

    const setQueueDeleteImages = (value: { sid: string; creationTimestamp: number }[]) => {
      queueDeleteImages.value = value
    }

    const setIsFinishMore = (val: boolean): void => {
      isFinishMore.value = val
    }

    return {
      imageSets,
      setImageSets,
      search,
      view,
      switchView,
      setView,
      views,
      filter,
      filterCount,
      isStarlessView,
      setFilter,
      resetFilter,
      filterPanel,
      switchFilterPanel,
      deleteSetTimer,
      setDeleteSetTimer,
      filterCategories,
      filterIds,
      addImageSet,
      removeImageSet,
      setStarlessView,
      annotationObject,
      setAnnotationObject,
      searchSelectedTag,
      setSearchSelectedTag,
      isFavorite,
      setIsFavorite,
      queueDeleteImages,
      setQueueDeleteImages,
      queueDeleteImageIds,
      isFinishMore,
      setIsFinishMore,
    }
  },
  persist: [
    {
      paths: ['view', 'annotationObject', 'queueDeleteImages', 'queueDeleteImageIds'],
      storage: localStorage,
    },
    {
      paths: ['deleteSetTimer'],
      storage: sessionStorage,
    },
  ],
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGalleryStore, import.meta.hot))
}
