<template>
  <span :data-id="file.fuid"></span>
</template>

<script setup lang="ts">
import { SLOW_NETWORK_TIME } from '@/core/constant'
import { FileInfo, FileStatus } from '@/core/utils/storage.idbdatabase'

const props = defineProps<{
  file: FileInfo
}>()

const DROPBOX_LARGE_FILE_THRESHOLD = 150 * 1024 * 1024 // 150MB in bytes
const CHUNK_SIZE = 150 * 1024 * 1024 // 150MB chunks
const MAX_RETRIES = import.meta.env.VITE_MAX_RETRIES_TIME
const DEFAULT_RETRY_DELAY = 5 // seconds

const uploadStore = useUploadStoreV2()

const { bus } = useEventBus()

const request = ref<XMLHttpRequest>(new XMLHttpRequest())

const handleUploadDropbox = async () => {
  const acceptStatus = [
    FileStatus.Queueing,
    FileStatus.Pausing,
    FileStatus.Cancelled,
    FileStatus.Failed,
  ]
  if (!acceptStatus.includes(props.file.status) || !props.file.upload_url || !props.file.file)
    return

  uploadStore.update({ ...props.file, status: FileStatus.Uploading, bytes_uploaded: undefined })
  await uploadLargeFile(props.file.file, props.file.upload_url)
}

const uploadLargeFile = async (file: File, filePath: string): Promise<void> => {
  let offset = 0
  let sessionId: string | null = null

  try {
    // Start the upload session
    sessionId = await startUploadDropboxSession(file.slice(0, Math.min(CHUNK_SIZE, file.size)))
    offset = Math.min(CHUNK_SIZE, file.size)

    // If file size is larger than CHUNK_SIZE, continue uploading chunks
    while (offset < file.size) {
      const chunk = file.slice(offset, Math.min(offset + CHUNK_SIZE, file.size))
      await appendToUploadSession(sessionId, chunk, offset)
      offset += chunk.size
    }

    // Always finish the upload session, even for small files
    const finalChunk = file.size > CHUNK_SIZE ? new Blob() : file // Empty blob if we've already uploaded all data
    await finishUploadSession(sessionId, finalChunk, offset, filePath)
  } catch (error) {
    uploadStore.update({ ...props.file, error: 'upload_failed', status: FileStatus.Failed })
  }
}

const startUploadDropboxSession = async (chunk: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    $sentry.setTag('upload_start', `${props.file.sid}:${props.file.fuid}`)

    let xhr = request.value
    xhr.open('POST', 'https://content.dropboxapi.com/2/files/upload_session/start', true)
    xhr.setRequestHeader('Authorization', `Bearer ${props.file.token}`)
    xhr.setRequestHeader('Content-Type', 'application/octet-stream')
    xhr.responseType = 'json'

    let previousBytesUploaded = 0
    const uploadData: { timestamp: number; bytesUploaded: number }[] = []
    let uploadSpeed = 0
    const calculateAverageSpeed = () => {
      const currentTime = Date.now()
      const cutoffTime = currentTime - 10000
      const recentData = uploadData.filter((data) => data.timestamp > cutoffTime)

      const totalBytesUploadedIn5Seconds = recentData.reduce(
        (acc, data) => acc + data.bytesUploaded,
        0,
      )

      const averageSpeed = totalBytesUploadedIn5Seconds / 5

      return averageSpeed
    }

    const intervalId = setInterval(() => {
      uploadSpeed = calculateAverageSpeed()
    }, 1000)

    xhr.upload.onprogress = (e) => {
      const bytesUploadedNow = e.loaded - previousBytesUploaded

      uploadData.push({ timestamp: Date.now(), bytesUploaded: bytesUploadedNow })

      while (uploadData.length > 0 && uploadData[0].timestamp <= Date.now() - 10000) {
        uploadData.shift()
      }

      previousBytesUploaded = e.loaded

      uploadStore.update({
        ...props.file,
        bytes_uploaded: e.loaded,
        network_speed: uploadSpeed ?? 0,
      })
    }

    xhr.onload = () => {
      clearInterval(intervalId)
      if (xhr.status === 200) {
        resolve(xhr.response.session_id)
      } else {
        reject({ status: xhr.status, response: xhr.response })
      }
    }

    xhr.onerror = () => {
      clearInterval(intervalId)
      reject({ status: xhr.status, response: xhr.response })
    }

    xhr.send(chunk)
  })
}

const appendToUploadSession = async (sessionId: string, chunk: Blob, offset: number) => {
  await retryableRequest(() => {
    return new Promise((resolve, reject) => {
      const xhr = request.value
      const url = 'https://content.dropboxapi.com/2/files/upload_session/append_v2'

      xhr.open('POST', url, true)
      xhr.setRequestHeader('Authorization', `Bearer ${props.file.token}`)
      xhr.setRequestHeader('Content-Type', 'application/octet-stream')
      xhr.setRequestHeader(
        'Dropbox-API-Arg',
        JSON.stringify({ cursor: { session_id: sessionId, offset: offset }, close: false }),
      )
      xhr.responseType = 'json'

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(new Response(xhr.response, { status: xhr.status, statusText: xhr.statusText }))
        } else {
          reject({ status: xhr.status, response: xhr.response })
        }
      }

      xhr.upload.onprogress = (e) => {
        uploadStore.update({ ...props.file, bytes_uploaded: offset + e.loaded })
      }

      xhr.onerror = () => reject({ status: xhr.status, response: xhr.response })

      xhr.send(chunk)
    })
  })
}

const finishUploadSession = async (
  sessionId: string,
  chunk: Blob,
  offset: number,
  filePath: string,
) => {
  const upload = () => {
    $sentry.setTag('upload_finish', `${sessionId}:${props.file.fuid}`)

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', 'https://content.dropboxapi.com/2/files/upload_session/finish', true)
      xhr.setRequestHeader('Authorization', `Bearer ${props.file.token}`)
      xhr.setRequestHeader('Content-Type', 'application/octet-stream')
      xhr.setRequestHeader(
        'Dropbox-API-Arg',
        JSON.stringify({
          cursor: { session_id: sessionId, offset: offset },
          commit: { path: filePath, mode: 'add', autorename: true, mute: false },
        }),
      )

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(new Response(xhr.response, { status: xhr.status, statusText: xhr.statusText }))
        } else {
          reject({ status: xhr.status, response: xhr.response })
        }
      }

      xhr.onerror = () => reject({ status: xhr.status, response: xhr.response })

      xhr.send(chunk)
    })
  }

  const response = (await retryableRequest(upload)) as Response
  const result = await response.json()
  handleUploadResponse({ status: response.status, response: JSON.stringify(result), filePath })
}

const handleUploadResponse = async (req: {
  status: number
  response: string
  filePath?: string
}) => {
  const handleSuccessfulUpload = async (response: any, filePath?: string) => {
    if (!response.is_downloadable) {
      throw new Error('Cannot download file')
    }

    if (filePath && response.path_display !== filePath) {
      throw new Error('File path mismatch')
    }

    uploadStore.update({
      ...props.file,
      hash: response.id,
      shasum: [response.content_hash],
      status: FileStatus.Completed,
    })
  }

  try {
    if (req.response.includes('error')) {
      throw new Error('Response includes error')
    } else if (req.status === 200) {
      const response = JSON.parse(req.response)
      await handleSuccessfulUpload(response, req.filePath)
    } else if (req.status === 429) {
      // @ts-ignore
      await handle429Error(req.response)
    } else {
      throw new Error('[handleUploadResponse] error', {
        cause: { response: req.response, status: req.status },
      })
    }
  } catch (error) {
    handleUploadError(req.status, req.response)
  } finally {
    // queNextFile()
  }
}

const handleUpload = () => handleUploadDropbox()

const handle429Error = async (response: Response): Promise<void> => {
  const retryAfter = response.headers.get('Retry-After')
  const retryAfterSeconds = retryAfter ? parseInt(retryAfter, 10) : DEFAULT_RETRY_DELAY // Default seconds if header is missing

  // log.warn(`Rate limit exceeded. Retrying after ${retryAfterSeconds} seconds.`)

  // Wait for the specified time
  await new Promise((resolve) => setTimeout(resolve, retryAfterSeconds * 1000))
}

const retryableRequest = async <T,>(requestFn: () => any): Promise<T> => {
  let retries = 0
  while (MAX_RETRIES === 'Infinity' || retries < Number(MAX_RETRIES)) {
    try {
      const response = await requestFn()
      if (response.ok) {
        return response
      } else if (response.status === 429) {
        await handle429Error(response)
        // Don't increment retries for 429 errors, as we're following the Retry-After header
      } else {
        throw new Error('[retryableRequest] error', {
          cause: { response, status: response.status },
        })
      }
    } catch (error) {
      // @ts-ignore
      if (typeof error === 'object' && error?.status === 429) {
        continue // Don't increment retries for 429 errors
      }
      $sentry.forceCaptureMessage('[retryableRequest] error', {
        level: 'error',
        extra: {
          error,
          uploadId: props.file.fuid,
          retryAttempt: retries + 1,
        },
      })
      retries++
      if (MAX_RETRIES !== 'Infinity' && retries >= Number(MAX_RETRIES)) {
        throw error
      }

      await new Promise((resolve) => setTimeout(resolve, DEFAULT_RETRY_DELAY * 1000))
    }
  }
  throw new Error('Max retries reached')
}
const handleUploadError = (reqStatus: number, responseStr?: string) => {
  let reason: FileInfo['error'] = 'network' // 409
  switch (reqStatus) {
    case 500:
      reason = 'busy'
      break
    case 410:
      reason = 'expired'
      break
    case 429:
      reason = 'rate_limit'
      break
    case 401:
      reason = 'access_token_issue'
      break
    default:
      break
  }

  if (reqStatus !== 429) {
    $sentry.forceCaptureException(`Upload error: ${reason}`, {
      extra: {
        uploadId: props.file.fuid,
        response: responseStr,
      },
    })
  }

  uploadStore.update({ ...props.file, error: reason, status: FileStatus.Failed })
}

// repeat on network errors or session expiration
const restart = async () => {
  if (props.file.status === FileStatus.Failed || props.file.status === FileStatus.Cancelled) {
    handleUpload()
  }
}
// pause / resume
const pauseUpload = async () => {
  uploadStore.update({ ...props.file, status: FileStatus.Pausing })
  request.value?.abort()
}
const resumeUpload = async () => {
  if (props.file.status === FileStatus.Pausing) {
    handleUpload()
  }
}
// event bus watchers
watch(
  () => bus.value.get('restartUploadSession'),
  (val) => {
    const [p] = val ?? []
    if (p.id === props.file.fuid) {
      restart()
    }
  },
)
watch(
  () => bus.value.get('pauseUpload'),
  (val) => {
    const [p] = val ?? []
    if (p.id === props.file.fuid) {
      pauseUpload()
    }
  },
)
watch(
  () => bus.value.get('resumeUpload'),
  (val) => {
    const [p] = val ?? []
    if (p.id === props.file.fuid) {
      resumeUpload()
    }
  },
)
onMounted(() => {
  handleUpload()
})
</script>
