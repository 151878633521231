import { Serializer, useEventListener, useStorage } from '@vueuse/core'
import { cloneDeep, isString } from 'lodash'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { FileInfo, IFileStorage } from '../utils/storage.idbdatabase'

// NOTE: use this struct instead of {fuid:'xxxx',status:'xxxx'} to reduce storage size
type FilesToSyncIDB = [FileInfo['fuid'], FileInfo['status']][]
const serializer: Serializer<FilesToSyncIDB> = {
  read: (v: string) => {
    if (isString(v)) {
      return v.split(',').map((i) => i.split('|')) as FilesToSyncIDB
    }
    return []
  },
  write: (v: FilesToSyncIDB) => v.map((i) => i.join('|')).join(','),
}

export const useUploadStoreV2 = defineStore('uploadV2', () => {
  const files = ref<FileInfo[]>([])
  const filesToSyncIDB = useStorage<FilesToSyncIDB>('$filesToSyncIDB', [], localStorage, {
    serializer,
  })
  const sortedFiles = computed(() => sortFiles(files.value))
  const setFiles = (newFiles: FileInfo[]): void => {
    files.value = newFiles
  }
  useEventListener(window, 'beforeunload', () => {
    filesToSyncIDB.value = files.value.map((f) => [f.fuid, f.status])
  })

  const fileStorage = ref<IFileStorage>()
  const init = async (): Promise<void> => {
    if (fileStorage.value) return

    const updateFileStatusesFromSync = (files: FileInfo[]): void => {
      files.forEach((file, index) => {
        const syncedStatus = filesToSyncIDB.value.find(([fuid]) => fuid === file.fuid)?.[1]
        if (syncedStatus) {
          files[index].status = syncedStatus
        }
      })
    }
    const separateFilesByStatus = (files: FileInfo[]) => {
      const isCompleted = (f: FileInfo) =>
        f.status === FileStatus.Completed ||
        f.status === FileStatus.Notifying ||
        f.status === FileStatus.Notified

      const completedFiles = files.filter(isCompleted)
      const notCompletedFiles = files.filter((f) => !isCompleted(f))

      return { completedFiles, notCompletedFiles }
    }
    const updateCompletedFiles = (files: FileInfo[]): void => {
      files.forEach((file) => update(file))
    }
    const removeIncompleteFiles = (files: FileInfo[]): void => {
      files.forEach((file) => remove(file.fuid))
    }

    const db = await initDatabase('upload-v3', 1)
    fileStorage.value = new FileStorage(db, (fileList: FileInfo[]) => setFiles(cloneDeep(fileList)))
    const oldFiles = await fileStorage.value.init()
    const oldFilesCloned = cloneDeep(oldFiles)

    // NOTE: Combine data from IndexedDB (idb) and filesToSyncIDB
    // - Use fuid from idb to identify files
    // - Update status from filesToSyncIDB if available
    // Reasons:
    // 1. filesToSyncIDB contains the most recent status saved before the webpage closed
    // 2. IndexedDB might contain older information
    // Result: Create a new array combining persistent data (idb) with updated statuses (filesToSyncIDB)
    updateFileStatusesFromSync(oldFilesCloned)
    const { completedFiles, notCompletedFiles } = separateFilesByStatus(oldFilesCloned)
    setFiles(completedFiles)
    updateCompletedFiles(completedFiles)
    removeIncompleteFiles(notCompletedFiles)
  }

  const push = (...file: FileInfo[]): void => {
    fileStorage.value?.saveFiles(cloneDeep(file))
  }
  const get = (fileId: string): FileInfo | undefined => {
    try {
      return fileStorage.value?.getFile(fileId)
    } catch (error) {
      return undefined
    }
  }
  const update = (file: FileInfo): void => {
    const oldFile = get(file.fuid)
    if (oldFile) {
      fileStorage.value?.updateFile(cloneDeep(file))
    }
  }
  const remove = (fileId: string): void => {
    const oldFile = get(fileId)
    if (oldFile) {
      fileStorage.value?.removeFile(fileId)
    }
  }
  const removeFolder = (folderName: string): void => {
    sortedFiles.value
      .filter((file) => file.file?.webkitRelativePath === folderName)
      .forEach(({ fuid }) => fileStorage.value?.removeFile(fuid))
  }

  const isVisibleUploadWindow = ref(false)
  const setIsVisibleUploadWindow = (value: boolean): void => {
    isVisibleUploadWindow.value = value
  }

  const isShowUploader = ref(false)
  const setIsShowUploader = (value: boolean): void => {
    isShowUploader.value = value
  }

  const uploadSessionTotal = ref<number>(0)

  const isNewFilesAdding = ref(false)
  const setIsNewFilesAdding = (value: boolean): void => {
    isNewFilesAdding.value = value
  }

  return {
    init,
    files: sortedFiles,
    setFiles,
    push,
    update,
    get,
    remove,
    removeFolder,
    isVisibleUploadWindow: computed(() => isVisibleUploadWindow.value),
    setIsVisibleUploadWindow,
    isShowUploader: computed(() => isShowUploader.value),
    setIsShowUploader,
    pendingFiles: computed(() => sortedFiles.value.filter((f) => f.status === FileStatus.Pending)),
    queuedFiles: computed(() => sortedFiles.value.filter((f) => f.status === FileStatus.Queueing)),
    uploadingFiles: computed(() =>
      sortedFiles.value.filter((f) => f.status === FileStatus.Uploading),
    ),
    failedFiles: computed(() => sortedFiles.value.filter((f) => f.status === FileStatus.Failed)),
    canceledFiles: computed(() =>
      sortedFiles.value.filter((f) => f.status === FileStatus.Cancelled),
    ),
    completedFiles: computed(() =>
      sortedFiles.value.filter((f) => f.status === FileStatus.Completed),
    ),
    notifyingFiles: computed(() =>
      sortedFiles.value.filter((f) => f.status === FileStatus.Notifying),
    ),
    hasUploadingOrQueue: computed(() => {
      return !!sortedFiles.value.find(
        (f) =>
          f.status === FileStatus.Uploading ||
          f.status === FileStatus.Queueing ||
          f.status === FileStatus.Pausing,
      )
    }),

    uploadSessionTotal,
    isNewFilesAdding: computed(() => isNewFilesAdding.value),
    setIsNewFilesAdding,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUploadStoreV2, import.meta.hot))
}
