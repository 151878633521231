<template>
  <div
    class="flex flex-wrap items-center justify-between rounded-md bg-gray-100 px-4 py-2 dark:bg-gray-800 md:flex-nowrap"
  >
    <div
      class="flex min-w-px flex-1 flex-col items-center md:mr-4 md:w-5/12 md:basis-10/12 md:flex-row"
    >
      <div
        class="relative mt-2 h-14 w-14 shrink-0 overflow-hidden rounded-md bg-gray-200 dark:bg-gray-700 md:mr-auto md:mt-0"
      >
        <img class="h-full w-full object-cover" v-if="dataURL" :src="dataURL" :alt="file.name" />

        <div
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-500 transition-[opacity] dark:text-white"
          v-else
        >
          <SvgIcon v-if="file.type === 'archive'" :path="mdiFolderZip" size="xl" />
          <SvgIcon
            v-else-if="['image', 'imagelike'].includes(file.type)"
            :path="mdiFileImage"
            size="xl"
          />
        </div>
      </div>
      <div class="text-md mt-2 w-full basis-full truncate px-4 md:mt-0 md:basis-auto md:pr-2">
        <span>{{ file.name }} ({{ formatBytes(file?.size ?? 0) }})</span>
        <template
          v-if="file.status === FileStatus.Completed || file.status === FileStatus.Notifying"
        >
          <br />
          <small>finalizing</small>
        </template>
        <p v-if="file.is_slow_network && uploadProgressVerbose !== 100" class="mt-1 text-red-500">
          Please be patient.
        </p>
      </div>
    </div>

    <div class="items-left mt-4 flex md:mt-0">
      <div class="relative flex h-6 w-full items-center justify-center" @click="statusButtonAction">
        <UploadStatus :uploadProgressVerbose="uploadProgressVerbose" :file="file" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatBytes } from '@/core/utils/File'
import { FileInfo, FileStatus } from '@/core/utils/storage.idbdatabase'
import SvgIcon from '@c/Ui/SvgIcon.vue'
import { mdiFileImage, mdiFolderZip } from '@mdi/js'
import UploadStatus from './UploadStatus.vue'

const props = defineProps<{
  file: FileInfo
}>()

// create blob: URL
const dataURL = computed(() => {
  if (props.file.type === 'image' && (props.file?.size ?? 0) <= uploaderConfig.readerMaxSize) {
    return window.URL.createObjectURL(props.file?.file as Blob | MediaSource)
  }
  return null
})
onBeforeUnmount(() => {
  if (dataURL.value) {
    URL.revokeObjectURL(dataURL.value)
  }
})

const uploadProgressVerbose = computed(() => {
  const bytesUploaded = getBytesUploaded(props.file.bytes_uploaded)

  return Math.round((bytesUploaded / (props.file.size ?? bytesUploaded)) * 100)
})

const router = useRouter()
const { busEmit } = useEventBus()
const restartUploadSession = (): void => {
  busEmit('restartUploadSession', { id: props.file.fuid })
}
const pauseUpload = (): void => {
  busEmit('pauseUpload', { id: props.file.fuid })
}
const resumeUpload = (): void => {
  busEmit('resumeUpload', { id: props.file.fuid })
}
const openResult = (): void => {
  router.push(`/image/${props.file.fuid}`)
}
const statusButtonAction = (): void => {
  switch (props.file.status) {
    case FileStatus.Notified:
      openResult()
      break
    case FileStatus.Uploading:
      pauseUpload()
      break
    case FileStatus.Pausing:
      resumeUpload()
      break
    case FileStatus.Failed:
    case FileStatus.Cancelled:
      restartUploadSession()
      break
  }
}
</script>
