export const thumbs = {
  small: [150, 150],
  medium: [400, 400],
  large: [800, 800],
  xlarge: [1920, 1080],
}

export const CHUNK_SIZE = 50 * 1024 * 1024 // 50MB
export const CONCURRENT_REQUESTS = 3
export const CONNECT_DROPBOX_ENDPOINT = 'dropbox'
export const DOWNLOAD_DISTANCE = 10 * 1000

export const SLOW_NETWORK_TIME = 30 * 1000
