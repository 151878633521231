import { ImageSizeTypes } from '@/components/Recognition/components/ImageOverlay/constants'
import { ImageSizeType, OrderType } from '../interface'

export function sortByField<T extends object>(
  array: T[],
  field: keyof T,
  order: OrderType = 'asc',
) {
  return array.slice().sort((a: T, b: T) => {
    const valueA = a[field]
    const valueB = b[field]

    if (order === 'asc') {
      if (valueA < valueB) return -1
      if (valueA > valueB) return 1
    } else if (order === 'desc') {
      if (valueA > valueB) return -1
      if (valueA < valueB) return 1
    }

    return 0 // If values are equal, return 0
  })
}

export function getExtensionByFilename(filename?: string) {
  if (!filename) return ''
  return filename.split('.').pop()
}

export function getImageSize(width: number): ImageSizeType {
  const sortedThumbs = Object.entries(thumbs).sort((a, b) => b[1][0] - a[1][0])

  for (const [key, size] of sortedThumbs) {
    if (width >= size[0]) {
      return key as ImageSizeType
    }
  }

  return ImageSizeTypes.full
}

export const formatDateUS = (timestamp: number | undefined | null): string => {
  if (!timestamp) return ''
  const date = new Date(timestamp * 1000)
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  }).format(date)
}

export function concatenateUint8Arrays(...arrays: Uint8Array[]) {
  const totalLength = arrays.reduce((sum, array) => sum + (array?.length ?? 0), 0)

  const result = new Uint8Array(totalLength)

  let offset = 0
  arrays.forEach((array) => {
    if (offset + array.length > result.length) {
      return arrays[0]
    }
    result.set(array, offset)
    offset += array?.length ?? 0
  })

  return result
}

export const handleCopy = (text: string = '', callback?: () => void) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      callback?.()
    })
    .catch((err) => {
      console.error('Coppy error', err)
    })
}

export const convertArrayToObjectDeleteImageSets = (
  dataArray: { sid: string; creationTimestamp: number }[],
) => {
  const result: { sid: string[]; creationTimestamp: number[] } = { sid: [], creationTimestamp: [] }
  dataArray.forEach((item) => {
    result.sid.push(item?.sid)
    result.creationTimestamp.push(item?.creationTimestamp)
  })

  return result
}

export function formatDuration(seconds: number): string {
  if (typeof seconds !== 'number') return ''
  if (seconds < 60) {
    return '< 1m'
  }

  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  let result = ''

  if (hours > 0) {
    result += `${hours}h `
  }

  if (remainingMinutes > 0) {
    result += `${remainingMinutes}m`
  }

  return result.trim()
}
