<template>
  <div class="">
    <!-- top action -->
    <div class="relative mt-4 flex items-center justify-center" v-if="pendingFiles.length > 0">
      <template v-if="!isComplete">
        <UiButton size="small" :loading="isUploading" @click="startUploadSessionDropBox">
          Upload {{ pendingFiles.length }}
          {{ $t('uploader.folder.file', pendingFiles.length) }}
        </UiButton>
        <UiButton class="!absolute right-0" size="small" theme="danger" @click="clearAll">
          {{ $t('header.message.clearAll') }}
        </UiButton>
      </template>
    </div>

    <div class="mt-4" v-for="folder in Object.keys(filesByFolder)" :key="folder">
      <!-- folder -->
      <div class="flex items-center" v-if="folder">
        <div
          class="mr-4 inline-flex shrink-0 cursor-pointer items-center"
          @click="toggleExpanded(folder)"
        >
          <div
            class="mr-2 flex h-[60px] w-[60px] flex-col items-center justify-center overflow-hidden rounded-md bg-gray-100 text-gray-500 dark:bg-gray-800"
          >
            <SvgIcon :path="mdiFolder" size="xl" />
          </div>

          <div :class="['rotate-180 transition', expandedFolders.includes(folder) && 'rotate-0']">
            <SvgIcon :path="mdiChevronUp" />
          </div>
        </div>

        <span class="truncate pr-4 text-lg font-semibold">{{ folder.slice(0, -1) }}</span>
        <span class="ml-auto mr-2 shrink-0 text-sm">
          {{ filesByFolder[folder].length }}
          {{ $t('uploader.folder.file', filesByFolder[folder].length) }}
        </span>
        <span
          class="shrink-0 cursor-pointer px-2 py-2 text-gray-500 transition hover:text-red-500"
          @click="removeFolder(folder)"
          title="Remove folder"
        >
          <SvgIcon :path="mdiCloseCircle" />
        </span>
      </div>

      <!-- filelist within folder -->
      <div
        :class="[
          folder && 'ml-4 overflow-hidden',
          folder && !expandedFolders.includes(folder) && 'h-0',
        ]"
      >
        <UploaderFilePreview
          v-for="f in filesByFolder[folder]"
          :key="f.fuid"
          :file="f"
          class="mt-4"
        />
      </div>
    </div>

    <!-- bottom action -->
    <div class="mt-4 flex items-center justify-center" v-if="pendingFiles.length > 5">
      <UiButton
        size="small"
        v-if="!isComplete"
        :loading="isUploading"
        @click="startUploadSessionDropBox"
      >
        Upload {{ pendingFiles.length }}
        {{ $t('uploader.folder.file', pendingFiles.length) }}
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FileInfo } from '@/core/utils/storage.idbdatabase'
import {
  RefreshUploadToken,
  RefreshUploadTokenDocument,
  RefreshUploadTokenVariables,
  RequestFileUploadSessionDropbox,
  RequestFileUploadSessionDropboxVariables,
} from '@/graphql/generated'
import UiButton from '@c/Ui/Button.vue'
import SvgIcon from '@c/Ui/SvgIcon.vue'
import UploaderFilePreview from '@c/Uploader/PreviewFile.vue'
import { mdiChevronUp, mdiCloseCircle, mdiFolder } from '@mdi/js'

const emit = defineEmits(['close-selector'])

const uiStore = useUiStore()

const uploadStore = useUploadStoreV2()
const { pendingFiles, uploadSessionTotal } = storeToRefs(uploadStore)

const isUploading = ref(false)
const isComplete = ref(false)

const expandedFolders = ref<string[]>([])

const toggleExpanded = (folder: string): void => {
  if (expandedFolders.value.includes(folder)) {
    expandedFolders.value = expandedFolders.value.filter((x) => x !== folder)
  } else {
    expandedFolders.value.push(folder)
  }
}

// group by folders
const filesByFolder = computed(() => {
  return pendingFiles.value.reduce(
    (acc, x) => {
      acc[x.folder] = acc[x.folder] || []
      acc[x.folder].push(x)
      return acc
    },
    {} as { [key: string]: FileInfo[] },
  )
})

// remove
const removeFolder = (folder: string): void => {
  uploadStore.removeFolder(folder)
}

// request upload session
const { client } = useApiClient()

async function refreshToken(
  token: NonNullable<
    NonNullable<RequestFileUploadSessionDropbox>['requestFileUploadSessionDropbox']
  >['token'],
  sid: string,
) {
  const maxRetries = 5
  const retryDelay = 5 // seconds
  let retries = 0
  const tokenCloned = { ...(token ?? {}) }
  const currentTimestamp = Math.floor(Date.now() / 1000)

  if (tokenCloned.token_expiry && currentTimestamp > tokenCloned.token_expiry) {
    while (retries < maxRetries) {
      try {
        const response = await client.value.query<RefreshUploadToken, RefreshUploadTokenVariables>(
          RefreshUploadTokenDocument,
          {
            sid,
          },
          {
            requestPolicy: 'network-only',
          },
        )
        if (response.data?.refreshUploadToken) {
          tokenCloned.token = response.data.refreshUploadToken.token
          tokenCloned.token_expiry = response.data.refreshUploadToken.token_expiry

          break
        } else {
          throw { response: response }
        }
      } catch (error) {
        $sentry.forceCaptureException(error, {
          extra: {
            message: 'Get refresh upload token error',
            retryAttempt: retries + 1,
          },
        })
        retries++
        if (retries >= maxRetries) {
          uiStore.addToast({
            type: 'error',
            message: `Failed to refresh token after ${maxRetries} attempts. Please try again later.`,
          })
        }
        await new Promise((resolve) => setTimeout(resolve, retryDelay * 1000))
      }
    }
  }
  return tokenCloned
}

const startUploadSessionDropBox = async () => {
  if (isUploading.value || isComplete.value) return
  isUploading.value = true
  uploadSessionTotal.value = pendingFiles.value.length

  const requestFiles = pendingFiles.value.map((x) => ({
    fuid: x.fuid,
    origin_path: encodeURI(x.folder + x.name),
    size: x.file?.size ?? 0,
    mime_type: x.file?.type ?? 'image/tiff',
    origin_last_modified: Math.round((x.file?.lastModified ?? 0) / 1000),
  }))
  const { data, error } = await client.value.query<
    RequestFileUploadSessionDropbox,
    RequestFileUploadSessionDropboxVariables
  >(RequestFileUploadSessionDropboxDocument, { files: requestFiles })

  if (data?.requestFileUploadSessionDropbox && !error) {
    emit('close-selector')
    const { session, token, paths } = data.requestFileUploadSessionDropbox
    const tokenCloned = await refreshToken(token, session?.sid ?? '')

    const chunkedIds = paths?.map((x) => x?.fuid).filter((x) => x) || []
    pendingFiles.value.forEach((f) => {
      let bytesUploaded: FileInfo['bytes_uploaded'] = 0
      const isLargeFile = chunkedIds.includes(f.fuid)
      if (isLargeFile) {
        const totalChunks = Math.ceil((f.file?.size ?? 0) / uploaderConfig.chunkSizeLargeFile)
        bytesUploaded = JSON.stringify([...Array(totalChunks)].map((_) => 0))
      }

      uploadStore.update({
        ...f,
        sid: session.sid ?? undefined,
        error: session.error ?? undefined,
        token: tokenCloned?.token,
        token_expiry: tokenCloned?.token_expiry,
        upload_url: paths?.find((x) => x?.fuid === f.fuid)?.path,
        status: session.error ? FileStatus.Failed : FileStatus.Queueing,
        bytes_uploaded: bytesUploaded,
        start_uploaded_at: new Date().getTime(),
        name: f.file?.name ?? '',
        size: f.file?.size ?? 0,
      })
    })

    uploadStore.setIsVisibleUploadWindow(true)

    $sentry.setTag('uploadsession_new', session.sid)
  } else {
    const errorStr = verboseUrqlError(error)
    pendingFiles.value.forEach((f) => {
      uploadStore.update({ ...f, error: errorStr, status: FileStatus.Failed })
    })
    uiStore.addToast({ type: 'error', message: errorStr })
    $sentry.forceCaptureException(error, { extra: { data, errorStr } })
  }
}

const clearAll = (): void => {
  pendingFiles.value.forEach((u) => uploadStore.remove(u.fuid))
}
onUnmounted(() => {
  clearAll()
})
</script>
