<template>
  <div class="flex min-h-screen flex-col">
    <LayoutHeader />
    <!-- <LayoutMobileMenu /> -->
    <slot></slot>
    <ScrollToTop />
    <LayoutFooter />

    <!-- mask uploads for cross-page use -->
    <UploadContainer />

    <ToastContainer />
    <LoginDialog />
  </div>
</template>

<script setup lang="ts">
import LayoutFooter from '@c/Layout/Footer.vue'
import LayoutHeader from '@c/Layout/Header/index.vue'
import ToastContainer from '@c/Ui/Toast/Container.vue'
import UploadContainer from '@c/Uploader/UploadContainer.vue'
import ScrollToTop from '../Ui/Atom/ScrollToTop.vue'
import LoginDialog from '../Auth/Login/LoginDialog.vue'

const log = useLogger()
const sessionStore = useSessionStore()
const uiStore = useUiStore()
const router = useRouter()
const route = useRoute()
const { JWTtokens } = storeToRefs(sessionStore)

const { routeSessionStart } = useCognito()

// session start
await routeSessionStart({
  onExpired: () => {
    // router.replace('/auth/login')
    router.replace('/')
    uiStore.openLoginDialog = true
  },
})

// auth guard
router.beforeEach((to, from) => {
  if (to.meta.authRequired && !JWTtokens.value) {
    uiStore.openLoginDialog = true
    return {
      // path: '/auth/login',
      path: '/',
      query: { redirect: to.fullPath },
    }
  } else if (to.meta.disallowAuthorized && JWTtokens.value) {
    return {
      path: '/profile',
    }
  }
})
</script>
