<template>
  <div class="relative mx-auto w-full max-w-4xl">
    <div class="flex items-center justify-between">
      <span v-if="uploadingAndQueueFileList.length">Uploading...</span>
      <div class="ml-auto space-x-2">
        <UiButton
          v-if="failedAndCanceledFiles.length"
          type="button"
          size="small"
          theme="danger"
          @click="clearFailedUploads"
        >
          Clear failed uploads
        </UiButton>
        <UiButton
          v-if="uploadingAndQueueFileList.length"
          type="button"
          size="small"
          theme="danger"
          @click="cancelInProgressUploads"
        >
          Cancel
        </UiButton>
      </div>
    </div>

    <div v-if="notPendingFiles.length" class="pb-16">
      <UploadFileDisplay v-for="f in notPendingFiles" :key="f.fuid" :file="f" class="mt-4" />
    </div>

    <div v-else class="mx-auto mt-10 flex max-w-md flex-col items-center">
      <div class="w-full rounded-lg bg-blue-100 px-4 py-2 dark:bg-blue-600">
        <p class="text-center text-base">{{ $t('uploader.list.empty') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import UiButton from '@c/Ui/Button.vue'
import UploadFileDisplay from '@c/Uploader/UploadFileDisplay.vue'

const uploadStore = useUploadStoreV2()
const { files, failedFiles, canceledFiles, uploadingFiles, queuedFiles } = storeToRefs(uploadStore)
const notPendingFiles = computed(() => files.value.filter((x) => x.status !== FileStatus.Pending))
const failedAndCanceledFiles = computed(() => [...failedFiles.value, ...canceledFiles.value])
const uploadingAndQueueFileList = computed(() => [...uploadingFiles.value, ...queuedFiles.value])

const clearFailedUploads = (): void => {
  failedAndCanceledFiles.value.forEach((u) => uploadStore.remove(u.fuid))
}

const { busEmit } = useEventBus()
const cancelInProgressUploads = (): void => {
  uploadingAndQueueFileList.value.forEach((f) => {
    busEmit('pauseUpload', { id: f.fuid })
    uploadStore.remove(f.fuid)
  })
}
</script>
