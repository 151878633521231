<template>
  <div
    id="uploadProgress"
    class="fixed bottom-0 right-0 z-[9999] max-h-[80vh] min-w-[100vw] max-w-[100vw] overflow-hidden border bg-gray-100 text-gray-900 shadow-xl dark:border-gray-700 dark:bg-gray-800 dark:text-white sm:min-w-[200px] sm:rounded-t-xl md:right-4"
    :class="[expanded && 'md:w-[500px]']"
  >
    <div class="flex items-center gap-5 px-4 py-3 pr-1">
      <span>Uploads</span>
      <div class="ml-auto flex items-center">
        <div class="cursor-pointe cancel-upload">
          <SvgIcon
            :path="mdiCancel"
            size="lg"
            class="cursor-pointer text-red-600"
            @click="handleClearAll"
          />
          <div
            class="tooltip absolute mt-2 hidden -translate-x-1/2 whitespace-nowrap rounded bg-gray-600 p-1 text-sm"
          >
            Clear completed uploads
          </div>
        </div>
        <UiButton size="small" theme="link" @click="expanded = !expanded">
          <SvgIcon
            :path="mdiChevronUp"
            size="lg"
            aria-hidden="true"
            class="transition-transform duration-[250]"
            :class="[expanded && 'rotate-180 transform']"
          />
        </UiButton>
        <UiButton size="small" theme="link" @click="close">
          <SvgIcon :path="mdiClose" aria-hidden="true" @click="close" />
        </UiButton>
      </div>
    </div>
    <!-- Progress -->
    <div
      class="flex h-[1px] w-full overflow-hidden rounded-full bg-gray-800 dark:bg-gray-200"
      role="progressbar"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        class="flex flex-col justify-center overflow-hidden whitespace-nowrap rounded-full bg-blue-600 text-center text-xs text-white transition duration-500"
        :style="{
          width: `${isUploadDone ? 0 : uploadProgressPercentage}%`,
        }"
      ></div>
    </div>
    <div class="flex justify-between px-3 py-3 italic">
      <transition name="fade">
        <div v-if="!isUploadDone || failedFiles?.length" class="flex-1">
          <span v-if="!!allFileOnProcess">
            Uploading {{ allFileOnProcess }} files
            <span v-if="timeRemain">- {{ timeRemain }} remaining</span>
          </span>
          <span v-else-if="failedFiles.length"> {{ failedFiles.length }} uploads failed </span>
          <span v-else>Upload completed!</span>
        </div>
      </transition>
    </div>
    <!-- End Progress -->
    <template v-if="!routeSid && expanded && uploadingFiles.length">
      <div class="flex items-center justify-between p-3">
        <div v-if="hasUploadingOrQueue && !allFileOnProcess" class="flex items-center">
          <span class="mr-2">Loading files</span>
          <span
            class="h-4 w-4 animate-spin rounded-full border-2 border-gray-300 border-t-blue-600"
          ></span>
        </div>
      </div>
    </template>

    <div
      v-show="expanded"
      class="max-h-[70vh] overflow-auto bg-white px-2 py-4 dark:bg-gray-900 md:px-4"
    >
      <UploadList />
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/components/Ui/SvgIcon.vue'
import UiButton from '@c/Ui/Button.vue'
import { mdiCancel, mdiChevronUp, mdiClose } from '@mdi/js'
import { sumBy } from 'lodash'
import UploadList from './UploadList.vue'

const uploadStore = useUploadStoreV2()
const {
  hasUploadingOrQueue,
  uploadingFiles,
  uploadSessionTotal,
  files,
  pendingFiles,
  queuedFiles,
  completedFiles,
  notifyingFiles,
  failedFiles,
} = storeToRefs(uploadStore)

const close = () => {
  uploadStore.setIsVisibleUploadWindow(false)
  updatePosition()
}
const { updatePosition } = useGalleryScrollTopPosition()

const route = useRoute()
const routeSid = computed(() => {
  return route.params.sid as string
})

const allFileOnProcess = computed(() => {
  return (
    uploadingFiles.value.length +
    queuedFiles.value.length +
    completedFiles.value.length +
    notifyingFiles.value.length
  )
})

const uploadProgressPercentage = computed(() => {
  return 100 - (allFileOnProcess.value / uploadSessionTotal.value) * 100
})

let speedArray: number[] = []

const timeRemain = computed(() => {
  const totalFileNotUploaded = [...uploadingFiles.value, ...queuedFiles.value]
  const remainBytes =
    sumBy(totalFileNotUploaded, 'size') -
    sumBy(totalFileNotUploaded, (f) => getBytesUploaded(f.bytes_uploaded))
  const networkSpeed =
    (sumBy(uploadingFiles.value, 'network_speed') / uploadingFiles.value.length
      ? sumBy(uploadingFiles.value, 'network_speed') / uploadingFiles.value.length
      : sumBy(completedFiles.value, 'network_speed') / completedFiles.value.length) * 6
  if (speedArray.length < 8) {
    speedArray = [...speedArray, networkSpeed]
  } else {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    speedArray.shift()
    speedArray = [...speedArray, networkSpeed]
  }
  const speedAverage =
    speedArray.reduce((accumulator, currentValue) => accumulator + (currentValue ?? 0), 0) /
    speedArray.length
  if (!speedAverage) return ''
  return formatDuration(Number((remainBytes / speedAverage).toFixed(2)))
})

watch(
  () => allFileOnProcess.value,
  (newValue) => {
    if (!newValue) {
      isUploadDone.value = true
    } else {
      isUploadDone.value = false
    }
  },
)

const expanded = ref(true)
const isUploadDone = ref(false)

const handleClearAll = () => {
  files.value
    .filter((f) => f.status !== FileStatus.Pending)
    .forEach((f) => uploadStore.remove(f.fuid))
  updatePosition()
}

watch(expanded, () => {
  updatePosition()
})

onMounted(() => {
  updatePosition()
})
</script>

<style lang="css">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.cancel-upload:hover .tooltip {
  display: block;
}
</style>
