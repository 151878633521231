<template>
  <TransitionRoot appear :show="uiStore.openLoginDialog" as="template">
    <Dialog as="div" @close="handleClose" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="flex w-full max-w-md transform flex-col overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-800"
            >
              <DialogTitle
                as="span"
                class="mb-5 text-center text-2xl font-bold leading-6 text-gray-900 dark:text-white"
              >
                Sign in
              </DialogTitle>
              <div class="mt-2 flex flex-col gap-4">
                <GoogleLogin />
                <div class="divider">
                  <span class="text-xl">or</span>
                </div>
                <EmailLogin />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import GoogleLogin from './GoogleLogin.vue'
import EmailLogin from './EmailLogin.vue'

const uiStore = useUiStore()

const handleClose = () => {
  const paypalButton = document.getElementById('paypal-button')
  if (paypalButton) {
    paypalButton.style.display = 'initial'
  }
  uiStore.openLoginDialog = false
}
</script>

<style lang="css" scoped>
.divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.divider span {
  margin: 0 10px;
  color: #999;
  font-weight: bold;
}
</style>
