<script setup lang="ts">
import { IFormErrors } from '@/core/interface'
import AtomErrorMessage from '@c/Ui/Atom/ErrorMessage.vue'
import UiButton from '@c/Ui/Button.vue'
import UiInput from '@c/Ui/Input.vue'
import SvgIcon from '@c/Ui/SvgIcon.vue'
import { mdiEye, mdiEyeOff, mdiLoginVariant } from '@mdi/js'
import { cloneDeep } from 'lodash'

const { t } = useI18n()
const uiStore = useUiStore()
const router = useRouter()
const route = useRoute()

type LoginFormModel = {
  email: string
  password: string
}
type InputFields = keyof LoginFormModel
const { values, errors, hasErrors, apiError } = useForm<InputFields>({ email: '', password: '' })
const showPassword = ref(false)
const setField = <T extends keyof LoginFormModel>(path: T, value: LoginFormModel[T]) => {
  errors.value[path] = null

  const form = cloneDeep(values.value)
  form[path] = value
  values.value = form
}
const validate = () => {
  const errCollector: IFormErrors = {}

  if (!isHasLength(values.value.email)) {
    errCollector.email = t('inputs.email.validation.required')
  } else if (!isValidEmail(values.value.email)) {
    errCollector.email = t('inputs.email.validation.mask')
  } else {
    const passwordError = validatePassword(values.value.password, t)
    if (passwordError) {
      errCollector.password = passwordError
    }
  }

  errors.value = errCollector

  return {
    valid: !Object.keys(errCollector).some((err) => err),
    errors: errCollector,
  }
}

const { login, fetching } = useAuth()
const onSubmit = async () => {
  const { valid } = validate()
  if (!valid) return

  login({
    form: values.value,
    onLoginSuccess: () => {
      const redirectUrl = route.query.redirect?.toString()
        ? route.query.redirect?.toString()
        : '/gallery'
      router.replace(redirectUrl)
      uiStore.openLoginDialog = false
      uiStore.addToast({ type: 'success', message: t('auth.login.dialog.success') })
    },
    onLoginError: (e) => (apiError.value = e),
    onAuthenticateFailure: (e, cognitoUser) => {
      if (e.code === 'UserNotConfirmedException') {
        cognitoUser.resendConfirmationCode((err, result) => {})
        router.replace(`/auth/confirm?email=${values.value.email}`)
        uiStore.addToast({
          type: 'info',
          message: t('auth.login.dialog.notVerified', { email: values.value.email }),
        })
        return
      }
      apiError.value = e.message
    },
  })
}
</script>

<template>
  <form @submit.prevent="onSubmit">
    <AtomErrorMessage :title="apiError" />

    <div class="">
      <UiInput
        name="email"
        type="email"
        :placeholder="$t('inputs.email.label')"
        :value="values.email"
        :error="errors.email"
        :focusOnMount="true"
        @onChange="setField('email', $event)"
      />
    </div>
    <!-- password -->
    <div class="relative mt-4">
      <UiInput
        name="password"
        :type="showPassword ? 'text' : 'password'"
        :placeholder="$t('inputs.password.label')"
        :value="values.password"
        :error="errors.password"
        @onChange="setField('password', $event)"
      />
      <span
        class="absolute inset-y-0 right-0 z-10 flex cursor-pointer items-center pr-3 text-black"
        @click="showPassword = !showPassword"
      >
        <SvgIcon v-if="showPassword" :path="mdiEye" class="dark:text-white" />
        <SvgIcon v-else :path="mdiEyeOff" class="dark:text-white" />
      </span>
    </div>
    <!-- cta-->
    <div class="mt-4">
      <UiButton
        type="submit"
        class="w-full"
        :iconRight="mdiLoginVariant"
        :loading="fetching"
        :disabled="hasErrors"
      >
        {{ $t('auth.login.form.action') }}
      </UiButton>
    </div>
  </form>
</template>
