import { FileWithPath } from '../interface'
// import { nanoid } from 'nanoid'

export async function getAllFilesFromDataTransfer(dataTransferItemList: DataTransferItemList) {
  const fileEntries: FileSystemFileEntry[] = []
  const queue: any = []
  const files: File[] = []

  for (let i = 0; i < dataTransferItemList.length; i++) {
    queue.push(dataTransferItemList[i].webkitGetAsEntry()!)
  }

  while (queue.length > 0) {
    const entry = queue.shift()
    if (entry.isFile) {
      fileEntries.push(entry)
    } else if (entry.isDirectory) {
      queue.push(...(await readAllDirectoryEntries(entry!.createReader())))
    }
  }

  while (fileEntries.length > 0) {
    const entry = fileEntries.shift()
    const file = await getFileFromEntry(entry!)
    if (file) files.push(file)
  }
  return files
}

async function readAllDirectoryEntries(directoryReader: FileSystemDirectoryReader) {
  const entries = []
  let readEntries = (await readEntriesPromise(directoryReader)) as FileSystemEntriesCallback[]
  while (readEntries.length > 0) {
    entries.push(...readEntries)
    readEntries = (await readEntriesPromise(directoryReader)) as FileSystemEntriesCallback[]
  }
  return entries
}

async function readEntriesPromise(directoryReader: FileSystemDirectoryReader) {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject)
    })
  } catch (err) {
    // log.log(err)
  }
}

export async function getFileFromEntry(fileEntry: FileSystemFileEntry): Promise<File | undefined> {
  try {
    return new Promise((resolve, reject) => {
      fileEntry.file((file) => {
        // resolve({
        //   file,
        //   path: fileEntry.fullPath.slice(1),
        // })
        resolve(
          Object.defineProperty(file, 'webkitRelativePath', {
            value: fileEntry.fullPath.slice(1),
          }),
        )
      }, reject)
    })
  } catch (err) {
    return
  }
}
