import { FileInfo } from '@/core/utils/storage.idbdatabase'
import { useFileProcessing } from './useFileProcessing'
import { useFileStatus } from './useFileStatus'
import { useNotification } from './useNotification'

type UseUploaderReturnType = {
  filesToUpload: ComputedRef<FileInfo[]>
}

/**
 * Main hook for managing the file upload process.
 * Coordinates between file processing, status management, and notifications.
 *
 * @returns {UseUploaderReturnType} Object containing the list of files ready for upload
 *
 * @example
 * const { filesToUpload } = useUploader()
 */
export const useUploader = (): UseUploaderReturnType => {
  const uploadStore = useUploadStoreV2()
  const { queuedFiles, completedFiles, hasUploadingOrQueue } = storeToRefs(uploadStore)

  /**
   * File Processing:
   * - Watches queued files and processes them for upload
   * - Manages the upload queue and batch processing
   */
  const { filesToUpload, processQueuedFiles } = useFileProcessing(uploadStore)
  watch(queuedFiles, processQueuedFiles, { immediate: true })

  /**
   * Notification Management:
   * - Watches completed files and sends notifications to backend
   * - Throttles notifications to prevent overwhelming the server
   */
  const { throttledNotify, handleNotifyCompleteBatch } = useNotification(uploadStore)
  const isFirstTimeCompletedFilesChange = ref(true)
  watch(
    completedFiles,
    (val) => {
      if (isFirstTimeCompletedFilesChange.value) {
        isFirstTimeCompletedFilesChange.value = false
        throttledNotify()
      } else {
        if (hasUploadingOrQueue.value) {
          throttledNotify()
        } else {
          handleNotifyCompleteBatch(val)
        }
      }
    },
    { immediate: true },
  )

  /**
   * Status Management:
   * - Initializes and resets file statuses
   * - Ensures proper status transitions during the upload process
   */
  const { initializeStatuses } = useFileStatus(uploadStore)
  initializeStatuses()

  return {
    filesToUpload,
  }
}
