<template>
  <div class="">
    <UploadFileLogic v-for="f in filesToUpload" :key="f.fuid" :file="f" />
  </div>
</template>

<script setup lang="ts">
import { useUploader } from '@/composables/uploader/useUploader'
import UploadFileLogic from '@c/Uploader/UploadFileLogic.vue'

const { filesToUpload } = useUploader()
</script>
