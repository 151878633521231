import { ComposerTranslation } from 'vue-i18n'
import { IFormErrors } from '../interface'

/* eslint-disable no-useless-escape */
export const clearString = (v: string | number, removeSpaces?: boolean) => {
  let value = ''
  if (typeof v === 'string') {
    value = v.trim()
  } else if (typeof v === 'number') {
    value = `${v}`
  }

  if (removeSpaces) {
    value = value.replace(/[`~ !@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
  }

  return value
}

export const isValidNumber = (v: any) => {
  return Number.isFinite(+v)
}

export const isValidEmail = (v: string) => {
  const value = clearString(v)
  if (value.includes('+')) return false
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return reg.test(value)
}

export const isValidAddress = (v: string) => {
  const value = clearString(v)

  return value.split(' ').length >= 2 && /\d+/.test(value)
}

export const isValidNameSurname = (v: string) => {
  const value = clearString(v)

  const [surname, name] = value.split(' ')
  if (!surname || !name) return false
  return surname.length >= 2 && name.length >= 2
}

export const isHasLength = (v: string | number) => {
  return clearString(v).length > 0
}

export const validatePassword = (value: string, t: ComposerTranslation) => {
  if (!value) {
    return t('inputs.password.validation.required')
  }

  if (value.length < 8) {
    return t('inputs.password.validation.length')
  }

  if (!/^\S*$/.test(value)) {
    return t('inputs.password.validation.whitespace')
  }

  if (!/^(?=.*[A-Z]).*$/.test(value)) {
    return t('inputs.password.validation.uppercase')
  }

  if (!/^(?=.*[a-z]).*$/.test(value)) {
    return t('inputs.password.validation.lowercase')
  }

  if (!/^(?=.*[0-9]).*$/.test(value)) {
    return t('inputs.password.validation.digits')
  }

  if (!/^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/.test(value)) {
    return t('inputs.password.validation.symbols')
  }

  return null
}

export const passwordErrors = (value: string, t: ComposerTranslation) => {
  const errors = {} as IFormErrors
  if (!value) {
    errors.required = t('inputs.password.validation.required')
  }

  if (value.length < 8) {
    errors.length = t('inputs.password.validation.length')
  }

  if (!/^\S*$/.test(value)) {
    errors.whitespace = t('inputs.password.validation.whitespace')
  }

  if (!/^(?=.*[A-Z]).*$/.test(value)) {
    errors.uppercase = t('inputs.password.validation.uppercase')
  }

  if (!/^(?=.*[a-z]).*$/.test(value)) {
    errors.lowercase = t('inputs.password.validation.lowercase')
  }

  if (!/^(?=.*[0-9]).*$/.test(value)) {
    errors.digits = t('inputs.password.validation.digits')
  }

  if (!/^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/.test(value)) {
    errors.symbols = t('inputs.password.validation.symbols')
  }

  return errors
}
