import { FileInfo } from '@/core/utils/storage.idbdatabase'
import { take } from 'lodash'

type UseFileProcessingReturnType = {
  filesToUpload: ComputedRef<FileInfo[]>
  /**
   * Process files in queue and prepare them for upload
   * - Returns early if there are no queued files or if files are currently uploading
   * - Filters out files that are not in Pending or Queueing status
   * - Takes next batch of files from queue (based on maxBatch config)
   * - Updates the list of fuids to be uploaded
   */
  processQueuedFiles: () => void
}

/**
 * Hook to manage file processing and preparation for upload
 * @param uploadStore Store managing the upload files state
 * @returns {UseFileProcessingReturnType} Object containing list of files to upload and queue processing function
 */
export const useFileProcessing = (
  uploadStore: ReturnType<typeof useUploadStoreV2>,
): UseFileProcessingReturnType => {
  const { files, queuedFiles, uploadingFiles } = storeToRefs(uploadStore)

  const fuidsToUpload = ref<FileInfo['fuid'][]>([])

  const processQueuedFiles = (): void => {
    if (!queuedFiles.value.length || uploadingFiles.value.length) return

    const notPendingAndQueueingFiles = files.value.filter(
      (f) => ![FileStatus.Pending, FileStatus.Queueing].includes(f.status),
    )
    const nextBatch = take(queuedFiles.value, uploaderConfig.maxBatch)

    fuidsToUpload.value = [...notPendingAndQueueingFiles, ...nextBatch].map((f) => f.fuid)
  }

  return {
    filesToUpload: computed(() => files.value.filter((f) => fuidsToUpload.value.includes(f.fuid))),
    processQueuedFiles,
  }
}
