<template>
  <div class="flex items-center" :data-id="file.fuid">
    <!-- preview -->
    <div
      class="mr-4 flex h-[60px] w-[60px] shrink-0 flex-col overflow-hidden rounded-md bg-gray-100 dark:bg-gray-800"
    >
      <div class="relative flex min-h-0 flex-1 basis-full flex-col">
        <div
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-500 transition-[opacity] dark:text-white"
        >
          <SvgIcon v-if="file.type === 'archive'" :path="mdiFolderZip" size="xl" />
          <SvgIcon
            v-else-if="['image', 'imagelike'].includes(file.type)"
            :path="mdiFileImage"
            size="xl"
          />
        </div>
      </div>
    </div>

    <div class="flex w-[1px] min-w-[1px] flex-1 flex-col items-start pr-5">
      <!-- file info -->
      <div class="w-full text-sm leading-5">
        <p class="truncate">
          {{ $t('uploader.file.name') }}:
          <span class="font-medium">{{ file.name }} </span>
        </p>
        <p class="truncate">
          {{ $t('uploader.file.size') }}:
          <span class="font-medium">
            {{ formatBytes(file.file?.size ?? 0) }}
          </span>
        </p>
      </div>

      <!-- error -->
      <div v-if="file.error" class="mr-4 mt-2 inline-flex items-center text-red-500">
        <SvgIcon :path="mdiAlertCircleOutline" size="lg" class="mr-2 shrink-0" />
        <div class="text-sm font-medium">{{ file.error }}</div>
      </div>
    </div>

    <div class="align-self-center ml-auto flex shrink-0 justify-center">
      <UiButton
        theme="danger"
        size="small"
        :iconLeft="mdiCloseCircle"
        title="Remove"
        @click="removeFile"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatBytes } from '@/core/utils/File'
import { FileInfo } from '@/core/utils/storage.idbdatabase'
import UiButton from '@c/Ui/Button.vue'
import SvgIcon from '@c/Ui/SvgIcon.vue'
import { mdiAlertCircleOutline, mdiCloseCircle, mdiFileImage, mdiFolderZip } from '@mdi/js'

const props = defineProps<{
  file: FileInfo
}>()

const uploadStore = useUploadStoreV2()
const removeFile = (): void => {
  uploadStore.remove(props.file.fuid)
}
</script>
