type UseFileStatusReturnType = {
  /**
   * Initializes file statuses by resetting both uploading and notifying files
   * if they exist in their respective queues
   */
  initializeStatuses: () => void
}

/**
 * Hook to manage and reset file statuses in the upload process
 * @param uploadStore Store managing the upload files state
 * @returns {UseFileStatusReturnType} Object containing function to initialize file statuses
 */
export const useFileStatus = (
  uploadStore: ReturnType<typeof useUploadStoreV2>,
): UseFileStatusReturnType => {
  const { uploadingFiles, notifyingFiles } = storeToRefs(uploadStore)

  /**
   * Resets all uploading files back to Queueing status
   * and clears their uploaded bytes
   */
  const resetUploadingFiles = (): void => {
    uploadingFiles.value.forEach((file) => {
      uploadStore.update({
        ...file,
        status: FileStatus.Queueing,
        bytes_uploaded: undefined,
      })
    })
  }

  /**
   * Marks all notifying files as Completed
   */
  const resetNotifyingFiles = (): void => {
    notifyingFiles.value.forEach((file) => {
      uploadStore.update({ ...file, status: FileStatus.Completed })
    })
  }

  const initializeStatuses = (): void => {
    if (uploadingFiles.value.length) resetUploadingFiles()
    if (notifyingFiles.value.length) resetNotifyingFiles()
  }

  return {
    initializeStatuses,
  }
}
